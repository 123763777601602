import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  ListItem,
  Collapse,
} from '@material-ui/core';
import { LoginContext } from '@/Main.jsx';
import Logo from '@/static/image/logo.png';
import DashboardIcon from '@/static/icon/SideBar/dashboard.svg';
import MapsIcon from '@/static/icon/SideBar/map.svg';
import TaskIcon from '@/static/icon/SideBar/task.svg';
import AccountIcon from '@/static/icon/SideBar/account.svg';
import SettingIcon from '@/static/icon/SideBar/setup.svg';
import SerialIcon from '@/static/icon/SideBar/serialnumber.svg';
import OrganizationIcon from '@/static/icon/SideBar/organization.svg';
import OrderIcon from '@/static/icon/SideBar/order.svg';
import OrderChildIcon from '@/static/icon/SideBar/order.png';
import NotificationIcon from '@/static/icon/SideBar/notification.svg';
// import actions from '@/actions';
import { AppConfig } from '@/config/index';
import { useDispatch } from 'react-redux';
import { setSiteData } from '@/reducer/mapListSlice';
import * as Style from './SideBarStyle';
import { sidebarItems } from '../Data.jsx';

const packageName = require('../../../package.json').name;

let { SERVICE_DISABLE } = AppConfig;
if (SERVICE_DISABLE === 'false') SERVICE_DISABLE = false;

const SideBar = (props) => {
  const { t, refreshPermisison, actions } = props; // use i18n

  const contextValue = useContext(LoginContext);

  const { setClickNum, clickNum } = contextValue;
  const dispatch = useDispatch();
  const [sideBarList, setSideBarList] = useState([]);

  // 取得目前頁面
  // let { currentPage } = contextValue;
  let currentPage = sessionStorage.getItem('currentPage');
  if (typeof currentPage === 'undefined') currentPage = sessionStorage.getItem('currentPage');
  const currentPageName = sessionStorage.getItem('currentPageName');

  // 取得側邊選單是否開啟
  const { menuIsOpen } = contextValue;

  const setPermission = () => {
    if (JSON.parse(sessionStorage.getItem('authMap')) !== null) {
      contextValue.authMap = JSON.parse(sessionStorage.getItem('authMap'));
      contextValue.authMap.Init = true;
    }

    const sideBarData = [
      {
        id: sidebarItems.dashboard.id,
        name: sidebarItems.dashboard.name,
        icon: <DashboardIcon />,
        isDisable: !contextValue.authMap.Dashboard,
      },
      {
        id: sidebarItems.notification.id,
        name: sidebarItems.notification.name,
        icon: <NotificationIcon />,
        isDisable: !contextValue.authMap.Notification,
      },
      {
        id: sidebarItems.maps.id,
        name: sidebarItems.maps.name,
        icon: <MapsIcon />,
        isDisable: !contextValue.authMap.Maps,
      },
      {
        id: sidebarItems.tasks.id,
        name: sidebarItems.tasks.name,
        icon: <TaskIcon />,
        isDisable: !contextValue.authMap.Tasks,
      },
      {
        id: sidebarItems.account.id,
        name: sidebarItems.account.name,
        icon: <AccountIcon />,
        isDisable: !contextValue.authMap.Account,
      },
      {
        id: sidebarItems.serial.id,
        name: sidebarItems.serial.name,
        icon: <SerialIcon />,
        isDisable: !contextValue.authMap.Serial,
      },
      {
        id: sidebarItems.organization.id,
        name: sidebarItems.organization.name,
        icon: <OrganizationIcon />,
        isDisable: !contextValue.authMap.Organization,
      },
      {
        id: sidebarItems.services.id,
        name: sidebarItems.services.name,
        icon: <OrderIcon />,
        isDisable: !contextValue.authMap.Services,
        child: [
          {
            id: sidebarItems.order.id,
            name: sidebarItems.order.name,
            isDisable: !contextValue.authMap.Order,
          },
          {
            id: sidebarItems.script.id,
            name: sidebarItems.script.name,
            isDisable: !contextValue.authMap.Script,
          },
          {
            id: sidebarItems.inspection.id,
            name: sidebarItems.inspection.name,
            isDisable: !contextValue.authMap.Inspection,
            // isDisable: false,
          },
        ],
      },
      {
        id: sidebarItems.setting.id,
        name: sidebarItems.setting.name,
        icon: <SettingIcon />,
        isDisable: !contextValue.authMap.Setting,
        child: [
          {
            id: sidebarItems.version.id,
            name: sidebarItems.version.name,
            isDisable: !contextValue.authMap.Versioning,
          },
        ],
      },
      {
        id: sidebarItems.factoryTestMgmt.id,
        name: sidebarItems.factoryTestMgmt.name,
        icon: <SettingIcon />,
        isHidden: !contextValue.authMap.Product,
        child: [
          {
            id: sidebarItems.factorySerial.id,
            name: sidebarItems.factorySerial.name,
            isHidden: !contextValue.authMap.Product,
          },
          {
            id: sidebarItems.factoryTestItems.id,
            name: sidebarItems.factoryTestItems.name,
            isHidden: !contextValue.authMap.Product,
          },
          {
            id: sidebarItems.factoryTestSetting.id,
            name: sidebarItems.factoryTestSetting.name,
            isHidden: !contextValue.authMap.Product,
          },
          {
            id: sidebarItems.factoryTestReport.id,
            name: sidebarItems.factoryTestReport.name,
            isHidden: !contextValue.authMap.Product,
          },
          {
            id: sidebarItems.factoryTest.id,
            name: sidebarItems.factoryTest.name,
            isHidden: !contextValue.authMap.Product,
          },
        ],
      },
    ];
    setSideBarList([...sideBarData]);
  };

  const listenerPage = () => {
    props.history.listen((location, action) => {
      if (action === 'POP' && location.pathname === '/') {
        if (contextValue.authMap !== 'undefined' && !contextValue.authMap.Account) {
          props.history.push('Robots');
        } else {
          props.history.push('Account');
        }

        return false;
      }
      if (action === 'POP') {
        const pathName = location.pathname.split('/')[1];
        const targetPage = sideBarList.filter((item) => item.id === pathName);
        if (!targetPage.length) { targetPage.push({ id: pathName, name: `${pathName.toUpperCase()}_MGMT` }); }
        contextValue.setCurrentPage(targetPage[0].id);
        contextValue.setCurrentPageName(targetPage[0].name);
        sessionStorage.setItem('currentPage', targetPage[0].id);
        sessionStorage.setItem('currentPageName', targetPage[0].name);
      }
    });
  };

  const changePage = (id, name, isDisable) => {
    if (isDisable) return;

    sessionStorage.setItem('currentPage', id);
    sessionStorage.setItem('currentPageName', name);
    // 以下頁面是另外一個子應用，不需要切換頁面，直接將訊息傳到master頁面
    if (!['Order', 'Script'].includes(id)) {
      contextValue.setCurrentPage(id);
      contextValue.setCurrentPageName(name);
      dispatch(setSiteData({ id: 'EMPTY', name: '' }));
      props.history.push(`/${id}`);
    } else {
      // 通知master頁面切換
      actions.setGlobalState({
        ignore: packageName,
        user: {
          name: 'master',
          page: id,
          sideBarList,
        },
      });
    }
  };

  const addClickNum = () => {
    let num = clickNum + 1;
    if (num > 10) num = 10;
    setClickNum(num);
  };

  useEffect(() => {
    setPermission();
  }, [refreshPermisison]);

  useEffect(() => {
    listenerPage();
  }, []);

  const disabledStyle = {
    opacity: 0.3,
    cursor: 'not-allowed',
    transition: '0.2s',
  };

  return (
    <Style.SideBar style={{ width: `${menuIsOpen}px`, zIndex: 99 }}>

      <img className="sideBarLogo" alt="logo" src={Logo} onClick={addClickNum} />

      <ul className="sideBarGroup sideBarOverflow" style={{ overflow: 'auto', height: window.innerHeight * 0.8 }}>
        {
          sideBarList.filter((x) => !x.isHidden).map((item) => {
            if (!item.child) {
              return (
                <li
                  key={item.id}
                  className={item.isDisable ? 'disable' : ''}
                >
                  <button
                    type="button"
                    onClick={() => changePage(item.id, item.name, item.isDisable)}
                    className={currentPage === item.id ? 'sideBarItemActive' : 'sideBarItem'}
                  >
                    {item.icon}
                    {t(item.name)}
                  </button>
                </li>
              );
            }

            return (
              <React.Fragment key={item.id}>
                <ListItem
                  button
                  className={currentPage === item.id ? 'sideBarItemActive' : 'sideBarItem'}
                  onClick={() => {
                    changePage(item.id, item.name, item.isDisable);
                  }}
                  style={item.isDisable ? disabledStyle : {}}
                >
                  {item.icon}
                  {t(item.name)}
                </ListItem>
                <Collapse
                  in
                  timeout="auto"
                >
                  {
                    item.child.filter((x) => !x.isHidden).map((childItem) => (
                      <button
                        type="button"
                        className={[
                          currentPage === childItem.id ? 'sideBarItemActive' : 'sideBarItem',
                          childItem.isDisable ? 'disable sideBarChild' : 'sideBarChild',
                        ].join(' ')}
                        style={{
                          backgroundColor: currentPageName === childItem.name && 'rgba(255, 255, 255, .2)',
                        }}
                        onClick={() => changePage(childItem.id, childItem.name, childItem.isDisable)}
                        key={childItem.id}
                      >
                        <img alt="" src={OrderChildIcon} className="childIcon" />
                        {t(childItem.name)}
                      </button>
                    ))
                  }
                </Collapse>
              </React.Fragment>
            );
          })
        }
      </ul>
    </Style.SideBar>
  );
};

export default withRouter(SideBar);
