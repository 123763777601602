import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import actions from '@/actions';
import Verify from '@/page/Verify/Verify.jsx';
// import Mqtt, { subscribe, unSubscribe } from '@/components/Mqtt/Mqtt.jsx';
import Mqtt from '@/components/Mqtt/Mqtt.jsx';
// import SnackbarMqttNotif from '@/components/Snackbar/SnackbarMqttNotif.jsx';
import { apiVersionValueByOne, apiVersionValueCreate } from '@/cloud_api/version';
import SideBar from './components/SideBar/SideBar.jsx';
import Nav from './components/Nav/Nav.jsx';
import NotificationModal from './components/Modal/NotificationModal.jsx';
import { GetModalStyle, onlyMessageModalStyle } from './components/Modal/ModalStyle';
import ResetPassword from './page/ResetPassword/ResetPassword.jsx';
import BottomLeftMsg from './page/CMS/Components/BottomLeftMsg.jsx';
import PageNotFound from './page/PageNotFound/PageNotFound.jsx';
import { isLogin } from './common/authorization.js';
import { AuthMapTable } from './AuthMapTable.jsx';
import Reminder from './static/image/remider.png';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '@/components/daterangepicker.css';
import { AppConfig } from './config/index.js';

const packageName = require('../package.json').name;

const Login = lazy(() => import('./page/Login/Login.jsx'));
const Dashboard = lazy(() => import('./page/Dashboard/DashboardRoute.jsx'));
const OrderReport = lazy(() => import('./page/OrderReport/OrderReport.jsx'));
const Maps = lazy(() => import('./page/Maps/Maps.jsx'));
const Robots = lazy(() => import('./page/Robots/Robots.jsx'));
const Tasks = lazy(() => import('./page/Tasks/Tasks.jsx'));
const Database = lazy(() => import('./page/Database/Database.jsx'));
const Setting = lazy(() => import('./page/Setting/Setting.jsx'));
const Organization = lazy(() => import('./page/Organization/Organization.jsx'));
const Account = lazy(() => import('./page/Account/Account.jsx'));
const Personal = lazy(() => import('./page/Personal/Personal.jsx'));
const Serial = lazy(() => import('./page/SerialNo/SerialNo.jsx'));
const Permission = lazy(() => import('./page/Permission/Permission.jsx'));
const Allotment = lazy(() => import('./page/Allotment/Allotment.jsx'));
const Order = lazy(() => import('./page/Order/Order.jsx'));
const EditMap = lazy(() => import('./page/EditMap/EditMap.jsx'));
const ViewMap = lazy(() => import('./page/Maps/ViewMap.jsx'));
const CMS = lazy(() => import('./page/CMS/CMSRoute.jsx'));
const Services = lazy(() => import('./page/Services/Services.jsx'));
const Versioning = lazy(() => import('./page/Versioning/Versioning.jsx'));
const WelcomeMenu = lazy(() => import('./page/WelcomeMenu/WelcomeMenu.jsx'));
const Notification = lazy(() => import('./page/Notification/Notification.jsx'));
const Inspection = lazy(() => import('./page/Inspection/Inspection.jsx'));
const FactoryTestMgmt = lazy(() => import('./page/FactoryTest/FactoryTestMgmt.jsx'));
const FactorySerialNoList = lazy(() => import('./page/FactoryTest/FactorySerialNoList.jsx'));
const FactoryTestItems = lazy(() => import('./page/FactoryTest/FactoryTestItems.jsx'));
const FactoryTestSetting = lazy(() => import('./page/FactoryTest/FactoryTestSetting.jsx'));
const FactoryReport = lazy(() => import('./page/FactoryTest/FactoryReport.jsx'));
const FactoryTest = lazy(() => import('./page/FactoryTest/FactoryTest.jsx'));

const LoadingText = () => (
  <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    Loading...
  </div>
);

export const LoginContext = React.createContext();
let refreshPermissionRef = false;

const Main = () => {
  // const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ username: '' });
  const [token, setToken] = useState(isLogin());
  const [refresh, setRefresh] = useState('');
  const [currentPage, setCurrentPage] = useState();
  const [currentPageName, setCurrentPageName] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState(0);
  const [navShow, setNavShow] = useState(-98);
  const { t, i18n } = useTranslation();
  const [pauseAMRStatus, setPauseAMRStatus] = useState(false);
  const [modalStyle] = useState(GetModalStyle);
  const onlyMessageModal = onlyMessageModalStyle();
  const [reminderOpen, setReminderOpen] = useState(false);
  const [clickNum, setClickNum] = useState(0);
  let authMap = AuthMapTable;
  const [refreshPermisison, setRefreshPermission] = useState(false);
  const [triggerLogout, setTriggerLogout] = useState(0);
  const [bottomState, setBottomState] = useState({});
  const [organizationList, setOrganizationList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [expired, setExpired] = useState(false); // 是否過期
  Mqtt();
  // const [organization, setOrganization] = useState('');
  // const client = Mqtt() || {};

  let count = 0; // 計時器
  const expiredTime = 30 * 60; // 過期時間  30分鐘
  let timer; // 計時器

  // 計時
  const timerCount = () => {
    count += 1;

    if (count <= expiredTime) {
      setTimeout(timerCount, 1000); // 1秒後再次觸發計時器
    }
  };

  const startTimer = () => {
    count = 0;
    timerCount(); // 開始計時器
  };

  const resetTimeout = () => {
    if (!expired) {
      count = 0;
      clearTimeout(timer); // 清除之前的計時器
      timer = setTimeout(() => {
        // 時間到，過期
        setExpired(true);
        sessionStorage.setItem('tokenExpired', true);
      }, expiredTime * 1000);
    }
  };

  useEffect(() => {
    // 確認前端版本是否寫入
    checkUiVersion();

    sessionStorage.setItem('tokenExpired', expired);
    startTimer(); // 開始記數

    // 監聽滑鼠、鍵盤事件
    const mainElement = document.getElementById('main');
    mainElement.addEventListener('mousemove', resetTimeout);
    mainElement.addEventListener('keydown', resetTimeout);

    return () => {
      mainElement.removeEventListener('mousemove', resetTimeout);
      mainElement.removeEventListener('keydown', resetTimeout);
    };
  }, []);

  const checkUiVersion = async () => {
    const { data } = await apiVersionValueByOne('ui', AppConfig.UI_VERSION);
    console.log('checkUiVersion', data);
    // console.log('UI_DESCRIPTION', UI_DESCRIPTION);
    if (data.length === 0) {
      // 未寫入
      console.log('未寫入', AppConfig.UI_VERSION, AppConfig.UI_DESCRIPTION);
      await apiVersionValueCreate({
        name: 'ui',
        version: AppConfig.UI_VERSION,
        description: AppConfig.UI_DESCRIPTION,
      });
      // } else {
      //   // 已寫入
      //   console.log('已寫入');
    }
  };

  const lockScreen = (width, height) => {
    const isReminder = sessionStorage.getItem('reminderOptions');
    if (width < height) {
      if (!isReminder) {
        setReminderOpen(true);
        sessionStorage.setItem('reminderOptions', false);
      }
    } else {
      setReminderOpen(false);
    }
  };

  const listenerScreen = () => {
    window.addEventListener('resize', () => {
      const { outerWidth, outerHeight } = window;
      lockScreen(outerWidth, outerHeight);
    });
  };

  const refreshPermissionTable = () => {
    refreshPermissionRef = !refreshPermissionRef;
    setRefreshPermission(refreshPermissionRef);
  };

  const checkPermission = (permission) => {
    if (JSON.parse(sessionStorage.getItem('authMap')) !== null) {
      authMap = JSON.parse(sessionStorage.getItem('authMap'));
      authMap.Init = true;
    }

    if (!authMap.hasOwnProperty(permission)) {
      return false;
    }

    return authMap[permission];
  };

  const reminderBody = (
    <div style={modalStyle} className={onlyMessageModal.paper}>
      <div id="simple-modal-description">
        <h4 className={onlyMessageModal.title}>{t('REMINDER_MESSAGE')}</h4>
        <div className={onlyMessageModal.body}>
          <img alt="Reminder" src={Reminder} className={onlyMessageModal.img} />
          <p className={onlyMessageModal.text}>
            {t('SCREEN_MESSAGE')}
          </p>
        </div>
      </div>
    </div>
  );

  const history = useHistory();
  useEffect(() => {
    actions.onGlobalStateChange((state) => {
      if (state.ignore === packageName) return;
      if (state.user.logout) {
        // setTimeout(() => {
        //   console.log('Logout');
        //   setTriggerLogout((trigger) => trigger + 1);
        //   setTimeout(() => {
        //     setTriggerLogout(0);
        //   }, 500);
        // }, 1000);
        console.log('Logout');
        setTriggerLogout((trigger) => trigger + 1);
        setTimeout(() => {
          setTriggerLogout(0);
        }, 500);
      }
      console.log('[state]', state);
    }, true);
  }, [history]);

  useEffect(() => {
    const { outerWidth, outerHeight } = window;
    lockScreen(outerWidth, outerHeight);
    listenerScreen();
    organizationListAndRoleInit();
  }, []);

  // useEffect(() => {
  //   console.log('organization', organization);
  //   subscribe(['notification/' + organization]);
  //   return () => {
  //     unSubscribe(['notification/' + organization], dispatch);
  //   };
  // }, [organization]);

  // 重新整理頁面時，從sessionStorage取得資料
  const organizationListAndRoleInit = () => {
    const accessWicentral = sessionStorage.getItem('tokenWicentral');
    if (accessWicentral) {
      const { supplement, roles } = jwt_decode(accessWicentral);
      setRoles(roles);
      if (supplement) setOrganizationList(Object.values(supplement).filter((v) => v.organization_id));
    }
  };

  return (
    <LoginContext.Provider
      value={{
        userInfo,
        setUserInfo,
        token,
        setToken,
        refresh,
        setRefresh,
        currentPage,
        setCurrentPage,
        currentPageName,
        setCurrentPageName,
        menuIsOpen,
        setMenuIsOpen,
        navShow,
        setNavShow,
        clickNum,
        setClickNum,
        authMap,
        bottomState,
        setBottomState,
        organizationList,
        setOrganizationList,
        roles,
        setRoles,
      }}
    >
      <div
        className={token ? 'main' : 'main notLogin'}
        style={{ paddingLeft: `${menuIsOpen}px` }}
        id="main"
      >
        <HashRouter>
          {token && <SideBar t={t} i18n={i18n} refreshPermisison={refreshPermisison} actions={actions} authMap={authMap} />}
          {token && <Nav t={t} i18n={i18n} triggerLogout={triggerLogout} actions={actions} refreshPermissionTable={refreshPermissionTable} />}
          {/* {client && Object.keys(client).length > 0 && <SnackbarMqttNotif />} */}
          <Suspense fallback={<LoadingText />}>
            <Switch>
              <Route
                path="/"
                render={() => (
                  <Login t={t} i18n={i18n} refreshPermissionTable={refreshPermissionTable} actions={actions} />
                  // token
                  //   ? <Dashboard key="Dashboard" menuIsOpen={menuIsOpen} token={token} />
                  //   : <Login t={t} i18n={i18n} refreshPermissionTable={refreshPermissionTable} />
                )}
                exact
              />
              <Route
                path="/Verify/:token"
                render={() => (
                  <Verify key="Verify" />
                )}
              />
              <Route
                path="/ResetPassword/:token"
                render={() => (
                  <ResetPassword key="ResetPassword" />
                )}
              />
              <Route
                path="/Dashboard"
                render={() => (
                  token
                    ? [checkPermission('Dashboard') ? <Dashboard key="Dashboard" menuIsOpen={menuIsOpen} token={token} actions={actions} /> : <Redirect key="Dashboard" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Versioning"
                render={() => (
                  token
                    ? <Versioning key="Versioning" />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/WelcomeMenu"
                render={() => (
                  token
                    ? <WelcomeMenu key="WelcomeMenu" token={token} />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/OrderReport/:type/:startDate/:endDate"
                render={() => (
                  token
                    ? <OrderReport key="OrderReport" token={token} />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Maps/:id/ViewMap"
                render={() => (
                  token
                    ? <ViewMap t={t} i18n={i18n} />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Maps/:id/EditMap"
                render={() => (
                  token
                    ? <EditMap t={t} i18n={i18n} menuIsOpen={menuIsOpen} />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Maps"
                render={() => (
                  token
                    ? [checkPermission('Maps') ? <Maps key="Maps" pauseAMRStatus={pauseAMRStatus} setPauseAMRStatus={setPauseAMRStatus} menuIsOpen={menuIsOpen} /> : <Redirect key="Maps" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Robots"
                render={() => (
                  token
                    ? <Robots key="Robots" menuIsOpen={menuIsOpen} checkPermission={checkPermission} token={token} actions={actions} />
                    : <Redirect to="/" />)}
              />
              <Route
                path="/Tasks"
                render={() => (
                  token
                    ? [checkPermission('Tasks') ? <Tasks key="Tasks" token={token} /> : <Redirect key="Tasks" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Database"
                render={() => (
                  token
                    ? <Database t={t} i18n={i18n} />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Setting"
                render={() => (
                  token
                    ? [checkPermission('Setting') ? <Setting key="Setting" t={t} i18n={i18n} /> : <Redirect key="Setting" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Organization"
                render={() => (
                  token
                    ? [checkPermission('Organization') ? <Organization key="Organization" token={token} /> : <Redirect key="Organization" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Account"
                render={() => (
                  token
                    ? [checkPermission('Account') ? <Account key="Account" token={token} /> : <Redirect key="Account" to="/NoPermission" />]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Personal"
                render={() => (token ? <Personal key="Personal" /> : <Redirect to="/" />)}
              />
              <Route
                path="/Serial"
                render={() => (
                  token
                    ? [
                      checkPermission('Serial')
                        ? <Serial key="Serial" token={token} />
                        : <Redirect key="Serial" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Permission"
                render={() => (token ? <Permission /> : <Redirect to="/" />)}
              />
              <Route path="/Allotment" render={() => <Allotment key="Allotment" token={token} />} />
              <Route path="/Order" render={() => <Order menuIsOpen={menuIsOpen} token={token} />} />

              <Route
                path="/CMS"
                render={() => (
                  token
                    ? <CMS key="CMS" />
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Services"
                render={() => (
                  token
                    ? [
                      checkPermission('Services')
                        ? <Services key="Services" />
                        : <Redirect key="Services" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/Inspection"
                render={() => (
                  <Inspection key="Inspection" token={token} />
                  // token
                  //   ? [
                  //     checkPermission('Inspection')
                  //       ? <Inspection key="Inspection" />
                  //       : <Redirect key="Inspection" to="/Inspection" />,
                  //   ]
                  //   : <Redirect to="/" />
                )}
              />
              <Route
                path="/Notification"
                render={() => (
                  token
                    ? [
                      checkPermission('Notification')
                        ? <Notification key="Notification" token={token} />
                        : <Redirect key="Notification" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactoryTestMgmt"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactoryTestMgmt key="Product" token={token} />
                        : <Redirect key="FactoryTestMgmt" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactorySerial"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactorySerialNoList key="Product" token={token} />
                        : <Redirect key="FactorySerial" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactoryTestItems"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactoryTestItems key="Product" token={token} />
                        : <Redirect key="FactoryTestItems" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactoryTestSetting"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactoryTestSetting key="Product" token={token} />
                        : <Redirect key="FactoryTestSetting" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactoryTestReport"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactoryReport key="Product" token={token} />
                        : <Redirect key="FactoryTestReport" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route
                path="/FactoryTest"
                render={() => (
                  token
                    ? [
                      checkPermission('Product')
                        ? <FactoryTest key="Product" token={token} />
                        : <Redirect key="FactoryTest" to="/NoPermission" />,
                    ]
                    : <Redirect to="/" />
                )}
              />
              <Route component={PageNotFound} />
            </Switch>
          </Suspense>

          <BottomLeftMsg
            setBottomState={setBottomState}
            bottomState={bottomState}
          />
        </HashRouter>
      </div>
      <Modal
        open={reminderOpen}
        onClose={() => setReminderOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {reminderBody}
      </Modal>
      <NotificationModal />
      {/* <NotificationModalTTT /> */}
    </LoginContext.Provider>
  );
};

export default Main;
